<template>
	<div>
		<scabe class="mx-2 my-3 border-b border-black" :name="me.name" />
		<div class="py-2 border-b border-black w-3/5 mx-2 font-bold">
			<p class="mt-2">Cotizaciones</p>
			<p class="mt-2">Lista de Cotizaciones</p>
		</div>
		<div
			v-if="
				PedidoVendedorCotizacion != '' ||
					noticacionCotizacionProductoinexistente != ''
			"
		>
			<div v-if="PedidoVendedorCotizacion != ''" class="my-2 font-bold mx-2">
				Completar cantidades
			</div>
			<div
				v-for="(cotizacion, k) in PedidoVendedorCotizacion"
				:key="k"
				class="border-b border-black mx-2 font-bold flex items-center"
			>
				<p class="my-2 mr-5">Cotizacion #{{ cotizacion.cotizacion_id }}</p>
				<router-link
					:to="{
						name: 'Vcotizacion',
						params: { id: cotizacion.cotizacion_id, name: me.name }
					}"
				>
					<botonP
						botonpText="Completar"
						class="flex items-center justify-center my-6"
					/>
				</router-link>
			</div>
			<div
				v-if="noticacionCotizacionProductoinexistente != ''"
				class="my-2 font-bold mx-2"
			>
				Productos nuevos
			</div>
			<div
				v-for="(cotizacion, k) in noticacionCotizacionProductoinexistente"
				:key="k"
				class="border-b border-black mx-2 mb-3 font-bold flex items-center"
			>
				<p class="my-2 mr-5">Cotizacion #{{ cotizacion.cotizacion_id }}</p>
				<router-link
					:to="{
						name: 'Vcotizacion',
						params: { id: cotizacion.cotizacion_id, name: me.name }
					}"
				>
					<botonP
						botonpText="Completar"
						class="flex items-center justify-center my-6"
					/>
				</router-link>
			</div>
		</div>
		<div v-else>
			No hay cotizaciones asignadas para este momento
		</div>
	</div>
</template>

<script>
import botonP from "@/components/botonPrimario.vue";
import scabe from "@/components/subCabeceraV.vue";
import gql from "graphql-tag";
export default {
	data() {
		return {
			me: ""
		};
	},
	components: {
		botonP,
		scabe
	},
	mounted() {
		this.$store.state.isLoading = true;
		this.$apollo
			.query({
				query: gql`
					query me {
						me {
							name
							id
							vendedores {
								id
								disponible
							}
						}
					}
				`
			})
			.then(data => {
				this.$store.state.isLoading = false;
				this.me = data.data.me;
			})
			.catch(error => {
				this.$store.state.isLoading = false;
				console.log(error);
			});
	},
	apollo: {
		PedidoVendedorCotizacion: {
			query: gql`
				query PedidoVendedorCotizacion($id: ID!) {
					PedidoVendedorCotizacion(vendedor_id: $id) {
						cotizacion_id
					}
				}
			`,
			variables() {
				return {
					id: this.me.vendedores[0].id
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.PedidoVendedorCotizacion,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		},
		noticacionCotizacionProductoinexistente: {
			query: gql`
				query noticacionCotizacionProductoinexistente($user_receptor: String!) {
					noticacionCotizacionProductoinexistente(
						user_receptor: $user_receptor
					) {
						cotizacion_id
					}
				}
			`,
			variables() {
				return {
					user_receptor: this.me.vendedores[0].id.toString()
				};
			},
			fetchPolicy: "no-cache"
		}
	}
};
</script>
